.navbar {
  height: 90px;
  margin-bottom: 0px;
  position: absolute; }
  .navbar .navbar-extra-top {
    min-height: 50px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #e7e7e7;
    background-image: linear-gradient(to bottom, #fff 0, #f8f8f8 100%);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 5px rgba(0, 0, 0, 0.075); }
    .navbar .navbar-extra-top .shop-brand {
      text-align: center; }
    .navbar .navbar-extra-top .brand-logo {
      margin-top: 7px; }
  @media (max-width: 768px) {
    .navbar .expanded-nav {
      display: none; } }
  @media (min-width: 768px) {
    .navbar .collapsed-nav {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-form.shop-search-form input.form-control {
    width: 40em; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .navbar-form.shop-search-form input.form-control {
    width: 28em; } }

.navbar-form.shop-search-form .input-group-addon {
  cursor: pointer; }

.navbar-right shop-auth-form {
  padding-bottom: 10px;
  min-width: 160px;
  display: block; }

.dropdown-menu > li > a {
  margin-left: 0;
  color: #BCE1F7;
  text-decoration: none; }
  .dropdown-menu > li > a:hover {
    color: #337ab7; }

.alert {
  padding: 5px 10px;
  line-height: 20px; }

@media screen and (min-width: 320px) and (max-width: 766px) {
  .navbar {
    height: 3.2rem;
    width: 100%;
    border-bottom: none; }
    .navbar .navbar-header {
      height: 3.2rem; }
  .navbar-default .navbar-header {
    margin-right: 0; }
  .navbar-default .navbar-toggle {
    height: 3.2rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
    border: none; }
    .navbar-default .navbar-toggle:hover {
      background-color: transparent; }
    .navbar-default .navbar-toggle:hover {
      background-color: transparent; }
  .navbar-default .navbar-collapse {
    border: none;
    box-shadow: none;
    padding: 0px; }
  .navbar-default .icon_logo {
    width: 7rem;
    height: 3.2rem;
    line-height: 3.2rem;
    position: absolute;
    top: 0; }
    .navbar-default .icon_logo img {
      width: 1.28rem;
      height: 1.28rem;
      line-height: 1.28rem;
      position: absolute;
      top: 0.96rem;
      left: 0.8rem; }
    .navbar-default .icon_logo p a {
      position: absolute;
      left: 2.4rem; }
  .navbar-default .icon_language, .navbar-default .icon_img_en, .navbar-default .icon_img_zh {
    display: none; }
  .navbar-default .navbar-nav {
    margin: 0;
    background-color: #333333; }
    .navbar-default .navbar-nav li {
      height: 2.4rem;
      line-height: 2.4rem;
      border-top-color: rgba(255, 255, 255, 0.2);
      border-top-width: 1px;
      border-top-style: solid;
      padding-left: 1.8rem; }
      .navbar-default .navbar-nav li a {
        height: 2.4rem;
        line-height: 2.4rem;
        padding: 0;
        padding-top: 0;
        padding-bottom: 0;
        color: #BCE1F7; }
      .navbar-default .navbar-nav li a:hover {
        color: #289CFF; }
      .navbar-default .navbar-nav li a:focus {
        color: #289CFF; }
      .navbar-default .navbar-nav li a:active {
        color: #289CFF; }
    .navbar-default .navbar-nav > .active a {
      color: #289CFF;
      background-color: #333333; }
      .navbar-default .navbar-nav > .active a:hover {
        background-color: #333333; }
      .navbar-default .navbar-nav > .active a:focus {
        background-color: #333333; }
      .navbar-default .navbar-nav > .active a:active {
        background-color: #333333; } }

@media screen and (min-width: 768px) {
  .navbar {
    height: 90px; }
  .container {
    padding: 0; }
  .navbar-default {
    border: none; }
    .navbar-default .navbar-nav {
      transition: all linear 3s;
      padding-top: 0px;
      animation: 0.5s myChange;
      -webkit-animation: 0.5s myChange;
      -o-animation: 0.5s myChange;
      -moz-animation: 0.5s myChange;
      -ms-animation: 0.5s myChange; }
  @keyframes myChange {
    from {
      padding-top: 90px; }
    to {
      padding-top: 0px; } }
  @-webkit-keyframes myChange {
    from {
      padding-top: 90px; }
    to {
      padding-top: 0px; } }
  @-o-keyframes myChange {
    from {
      padding-top: 90px; }
    to {
      padding-top: 0px; } }
  @-moz-keyframes myChange {
    from {
      padding-top: 90px; }
    to {
      padding-top: 0px; } }
  @-ms-keyframes myChange {
    .navbar-default .navbar-nav from {
      padding-top: 90px; }
    .navbar-default .navbar-nav to {
      padding-top: 0px; } }
      .navbar-default .navbar-nav > li > a {
        line-height: 90px;
        margin-right: 14px;
        padding: 0;
        color: #BCE1F7;
        font-family: "";
        font-size: 20px; }
        .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
          font-family: "";
          font-size: 20px;
          color: #289CFF;
          background-color: transparent; }
      .navbar-default .navbar-nav > li:nth-child(1) {
        margin-left: 90px; }
        .navbar-default .navbar-nav > li:nth-child(1):after {
          left: 10px; }
      .navbar-default .navbar-nav > li:hover:after {
        display: block; }
      .navbar-default .navbar-nav > li:after {
        content: '';
        display: none;
        border: 1px solid #289CFF;
        margin-left: 10px;
        width: 30px;
        position: absolute;
        bottom: 20px;
        left: 30px; }
      .navbar-default .navbar-nav .active a {
        color: #289CFF;
        background-color: transparent; }
        .navbar-default .navbar-nav .active a:hover, .navbar-default .navbar-nav .active a:focus {
          color: #289CFF;
          background-color: transparent; }
      .navbar-default .navbar-nav .active:after {
        display: block; }
      .navbar-default .navbar-nav .open a, .navbar-default .navbar-nav .dropdown-menu a {
        color: #289CFF;
        background-color: transparent; }
        .navbar-default .navbar-nav .open a:hover, .navbar-default .navbar-nav .open a:focus, .navbar-default .navbar-nav .dropdown-menu a:hover, .navbar-default .navbar-nav .dropdown-menu a:focus {
          color: #289CFF;
          background-color: transparent; }
      .navbar-default .navbar-nav .open li a, .navbar-default .navbar-nav .dropdown-menu li a {
        line-height: 28px;
        padding: 15px 15px;
        font-size: 20px;
        color: #BCE1F7;
        letter-spacing: 0;
        text-align: center; }
    .navbar-default .icon_logo {
      width: 100px;
      height: 90px;
      line-height: 90px;
      position: absolute;
      top: 0px;
      z-index: 3;
      cursor: pointer; }
      .navbar-default .icon_logo img {
        position: absolute;
        top: 27px;
        width: 36px;
        height: 36px;
        line-height: 36px;
        left: 0; }
      .navbar-default .icon_logo p a {
        position: absolute;
        left: 40px;
        top: 0px;
        font-size: 20px;
        color: #BCE1F7;
        font-family: ""; }
        .navbar-default .icon_logo p a:hover {
          color: #289CFF;
          text-decoration: none; }
        .navbar-default .icon_logo p a:focus {
          color: #289CFF;
          text-decoration: none; }
    .navbar-default .icon_language {
      width: 30px;
      height: 90px;
      line-height: 90px;
      position: absolute;
      top: -90px;
      right: 15px;
      z-index: 3;
      cursor: pointer; }
      .navbar-default .icon_language img {
        width: 30px;
        height: 30px;
        line-height: 30px;
        left: 0; }
    .navbar-default .icon_search {
      right: 70px; } }

@media screen and (min-width: 1024px) {
  .navbar-default .navbar-nav > li:nth-child(1) {
    margin-left: 170px; }
  .navbar-default .navbar-nav > li a {
    line-height: 90px;
    margin-right: 15px;
    margin-left: 15px; } }

@media screen and (width: 768px) {
  .navbar div:nth-child(1) {
    padding-left: 30px; }
  .navbar-default .navbar-nav > li:after {
    left: 15px; }
  .navbar-default .navbar-nav > li:nth-child(1):after {
    left: -3px; }
  .navbar-default .icon_logo {
    left: 20px; }
    .navbar-default .icon_logo img {
      position: absolute;
      top: 30px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      left: 0; }
  .navbar-default .icon_language, .navbar-default .icon_img_en, .navbar-default .icon_img_zh {
    display: none; } }

@media screen and (width: 1024px) {
  .navbar div:nth-child(1) {
    padding-left: 30px; }
  .navbar-default .icon_logo {
    left: 100px; }
  .navbar-default .icon_img_en, .navbar-default .icon_img_zh, .navbar-default .icon_language {
    display: none; } }

/*# sourceMappingURL=../../../../../../../static/website/css/navbar.css.map */